import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Lanny = () => {
  return (
    <Layout>
      <Head title="Lanny Quarles" />
      <h3>Lanny Quarles</h3>
      {/* <p>AI / Machine learning artist, using Midjourney</p> */}
      <p>
        <OutboundLink href="https://www.facebook.com/lanny.quarles">
          Facebook
        </OutboundLink>
      </p>
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659592768/artnow/lanny%20quarles/lanny-2-min.jpg"
        alt="Lanny Quarles Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659592768/artnow/lanny%20quarles/lanny-1-min.jpg"
        alt="Lanny Quarles Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659592768/artnow/lanny%20quarles/lanny-3-min.jpg"
        alt="Lanny Quarles Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659592768/artnow/lanny%20quarles/lanny-4-min.jpg"
        alt="Lanny Quarles Art"
      />
      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1659592768/artnow/lanny%20quarles/lanny-5-min.jpg"
        alt="Lanny Quarles Art"
      />
    </Layout>
  );
};

export default Lanny;
